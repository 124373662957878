<template>
  <AccountUnverified v-if="!user.email_verified_at" />
  <div class="Subscription" v-else>
   <!--  <div style="background-color: #4158D0;background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);margin-bottom: 30px;text-align: center;color: #fff;padding: 12px;border-radius: 5px;font-size: 16px;"> 🎉 Launch Week Lifetime Deal - 50% Discount 🎉 The offer expires on November 8 🎉 </div> -->

    <template v-if="planChange">
      <base-alert v-if="planChange === 'success'"
                  type="info">Your plan was successfully updated</base-alert>
      <base-alert v-else-if="planChange === 'fail'"
                  type="error">Something went wrong</base-alert>
    </template>

    <div class="pricing-options-wrapper mb-4" v-if="user.app_sumo_status === 'active'">
      <div class="d-flex flex-nowrap pricing-options">
        <div class="SubscriptionPlans flex-fill">
          <div class="plan card" >
            <div class="card-header">
              <h1>Your current plan</h1>
            </div>
            <div>
              <ul class="feature-titles">
                <li>Teammates</li>
                <li>Monitored Websites/APIs</li>
                <li>Check Interval</li>
                <li>Regions Checks</li>
                <li>SSL Monitoring</li>
                <li>Email Alerts</li>
                <li>Slack, Telegram, Webhook Alerts</li>
                <li>SMS/Voice Calls Alerts</li>
                <li>Integration Twilio, SIGNL4</li>
                <li>Weekly Report </li>
                <li>Unlimited Status Pages</li>
                <li>Custom Domain</li>
                <li>Status Page Incident Updates</li>
                <li>Status Page, Badge Customization</li>
                <li>Password Protected</li>
                <li>Subscribers Notifications</li>
                <li>Subscribers</li>
                <li>Historical Uptime/Logs</li>
                <li>API Access</li>
                <li>Priority Support</li>
                <li>Remove Pulsetic Brand</li>
                <li>Free Trial</li>
              </ul>
            </div>
          </div>
        </div>
        <subscription-plan :plan="user.subscription_plan"/>
      </div>
    </div>

    <div class="mb-4 d-flex justify-content-center">
      <p class="mr-3" style="font-weight: 600; line-height:1.6">Monthly</p>
      <base-toggle :value="yearlyType"
                   checked-text="Yes"
                   unchecked-text="No"
                   class="even-smaller cursor-pointer"
                   @input="changePlanType($event)" />
      <p class="ml-3" style="font-weight: 600; line-height:1.6">Annual</p>
      <span class="discount-alert-wrapper">
        <span class="discount-alert">2 months free</span>
      </span>
    </div>

    <div class="loader" v-if="loadingPlans">
      <base-loader-dual-ring />
    </div>

    <div class="pricing-options-wrapper">
      <div v-if="subscriptionPlans.length && !loadingPlans" class="d-flex flex-nowrap pricing-options">
        <div class="SubscriptionPlans flex-fill">
          <div class="plan card" >
            <div class="card-header">
              <h1>Plans</h1>
            </div>
            <div>
              <ul class="feature-titles">
                <li class="list-items-title"><strong>UPTIME MONITORING</strong></li>
                <li>Monitored Websites/APIs</li>
                <li>Check Interval</li>
                <li>Regions Checks</li>
                <li>SSL Monitoring</li>
                <li>Keyword Monitoring</li>
                <li>Historical Uptime/Logs</li>

                <li class="list-items-title"><strong>TEAM</strong></li>
                <li>Teammates</li>
                <li>Notify-Only Seats Included</li>

                <li class="list-items-title"><strong>ALERTS & REPORTS</strong></li>
                <li>Email Alerts</li>
                <li>SMS/Voice Calls Alerts</li>
                <li>Slack, MS Teams, Discord & Telegram</li>
                <li>Zapier, Twilio, SIGNL4</li>
                <li>Webhooks</li>
                <li>Weekly Report </li>

                <li class="list-items-title"><strong>STATUS PAGES & BADGES</strong></li>
                <li>Status Pages</li>
                <li>Status Badges</li>
                <li>Status Page & Badge Customization</li>
                <li>Status Page Incident Updates</li>
                <li>Schedule Maintenance</li>
                <li>Custom Domain</li>
                <li>Password Protected</li>
                <li>Subscriber Notifications</li>
                <li>Status Page Subscribers</li>
                <li>Search Engine Opt-out</li>
                <li>Whitelabeled Status Page</li>

                <li class="list-items-title"><strong>ACCOUNT</strong></li>
                <li>Two-Factor Authentication</li>
                <li>Role Based Access</li>
                <li>Buy Additional Services</li>
                <li>Priority Support (24 hours)</li>
                <li>API</li>
              </ul>
            </div>
          </div>
        </div>
        <subscription-plan v-for="plan in subscriptionPlans" :key="plan.id" :plan="plan" :loading="loading" @select-plan="selectPlan"/>
      </div>
    </div>

    <base-alert type="error" v-if="error" class="mt-3">{{error}}</base-alert>

<!--    <div class="receipts" v-if="invoices.length > 0">-->
<!--      <div class="receipts-title" style="font-weight: 600;">Your Invoices</div>-->

<!--      <div class="receipts-table">-->
<!--        <table class="table">-->
<!--          <thead>-->
<!--          <tr>-->
<!--            <th>Date</th>-->
<!--            <th>Price</th>-->
<!--            <th>Invoice Link</th>-->
<!--          </tr>-->
<!--          </thead>-->
<!--          <tbody>-->
<!--          <tr v-for="invoice in invoices" :key="invoice.id" v-show="invoice.total > 0">-->
<!--            <td>{{ invoice.created | day }}</td>-->
<!--            <td>${{ invoice.total }}</td>-->
<!--            <td>-->
<!--              <a class="BaseButton normal primary is-outlined" style="width:166px" :href="invoice.invoice_pdf">Download Invoice</a>-->
<!--            </td>-->
<!--          </tr>-->
<!--          </tbody>-->
<!--        </table>-->
<!--      </div>-->
<!--    </div>-->
    <checkout-modal v-bind:plan="selectedPlan" />

  </div>
</template>

<script>
import subscriptionsApi from '@/api/subscriptionsApi.js'
import SubscriptionPlan from './SubscriptionPlan'
import CheckoutModal from './CheckoutModal'
import BaseAlert from '../../Base/BaseAlert'
import AccountUnverified from '../AccountUnverified'
import BaseLoaderDualRing from '@/components/Base/BaseLoaderDualRing.vue'
import BaseToggle from '@/components/Base/BaseToggle.vue'

export default {
  components: {
    BaseToggle,
    BaseLoaderDualRing,
    AccountUnverified,
    BaseAlert,
    CheckoutModal,
    SubscriptionPlan
  },

  data () {
    return {
      link: null,
      cancelling: false,
      receipts: [],
      cardMounted: false,
      stripe: null,
      paymentMethod: null,
      yearlyType: false,

      subscriptionPlans: [],
      columnWidth: null,
      selectedPlan: null,

      error: '',
      loading: false,
      loadingPlans: false,
      // invoices: [],
      planType: 'monthly'
    }
  },

  created () {
    if (this.user && this.user.subscription_plan.type === 'yearly') {
      this.yearlyType = true
      this.planType = this.user.subscription_plan.type
    }
    if (this.user.email_verified_at) this.getSubscriptionPlans()
    // this.getInvoices()
  },

  methods: {
    selectPlan (plan) {
      this.error = ''
      this.selectedPlan = plan
      if (this.selectedPlan.name === 'Free') {
        this.subscribeToFreePlan(plan)
      } else {
        // this.$modal.show('checkoutModal', { plan })
        // Send an api request for stripe session url link
        // Redirect the e user to that link in the same tab
        this.redirectToPayment(plan)
      }
    },

    async getSubscriptionPlans () {
      this.loadingPlans = true
      try {
        this.subscriptionPlans = await subscriptionsApi.getSubscriptionPlans(this.planType)
        this.subscriptionPlans.sort(function (a, b) {
          return a.price - b.price
        })
        this.columnWidth = 99 / this.subscriptionPlans.length + '%'
        await this.addCustomPrices()
      } catch (e) {
        console.error(e.response)
      }
      this.loadingPlans = false
    },

    async addCustomPrices () {
      this.subscriptionPlans = this.subscriptionPlans.map((plan) => {
        if (plan.name === 'Start') {
          if (this.planType === 'monthly') {
            plan.custom_price = 9
            plan.price = 19
          } else if (this.planType === 'yearly') {
            plan.custom_price = 90
            plan.price = 190
          }
        }
        return plan
      })
    },

    async cancel () {
      this.cancelling = true

      await subscriptionsApi.subscribeToFree()

      window.location.reload()
    },

    // async getInvoices () {
    //   try {
    //     const response = await subscriptionsApi.getInvoices()
    //     this.invoices = response.data.data
    //   } catch (error) {
    //     console.error(error)
    //   }
    // },

    async subscribeToFreePlan (plan) {
      this.loading = true
      try {
        await subscriptionsApi.subscribeToFree(plan)
        window.location.reload()
      } catch (error) {
        this.error = error.response.statusText
      }
      this.loading = false
    },

    async redirectToPayment (plan) {
      try {
        const response = await subscriptionsApi.getStripeSessionUrl(plan.id)
        if (response['is_downgrading']) {
          const price = await subscriptionsApi.calculateTax(plan.id)
          this.$modal.show('checkoutModal', { plan, price })
        } else if (response['checkout_url']) {
          window.open(response['checkout_url'])
        }
      } catch (e) {
        console.error(e)
        this.error = e
      }
    },

    changePlanType (type) {
      this.planType = type ? 'yearly' : 'monthly'
      this.getSubscriptionPlans()
    }
  },

  computed: {
    ...mapState('authentication', [
      'user'
    ]),

    planChange () {
      if (this.$route.query.plan_change) {
        if (this.$route.query.plan_change === 'success' && this.$route.query.price) {
          window.plausible('Purchase', { revenue: { currency: 'USD', amount: this.$route.query.price } })
        }
        return this.$route.query.plan_change
      }
      return null
    }
  }
}
</script>
<style lang="scss" scoped>
  .Subscription {
    /*.plan {*/
    /*  background-color: map-get($colors, background);*/
    /*  ul {*/
    /*    list-style: none;*/
    /*    padding: 0;*/
    /*    li {*/
    /*      margin-bottom: 8px;*/
    /*    }*/
    /*    .float-right {*/
    /*      float: right;*/
    /*      font-weight: bold;*/
    /*    }*/
    /*  }*/
    /*}*/
    .feature-titles {
      font-weight: 600;
    }

    .list-items-title {
      font-size: 16px !important;
      border: none !important;
    }

    .discount-alert {
      position: absolute;
      bottom: 5%;
      border-radius: 22px;
      padding: 5px 10px 6px;
      font-weight: 500;
      font-size: 13px;
      line-height: 12px;
      display: inline-block;
      white-space: nowrap;
      left: calc(100% + 10px);
      background: #2d2d2d;
      color: #ffffff;

      &-wrapper {
        position: relative;
        display: block;
      }
    }

    .receipts {
      margin-top: 40px;
      &-table {
        overflow: auto;
        max-width: 100%;
      }
    }

    .receipts-title {
      margin-bottom: 10px;
      font-size: 18px;
    }

    .table {
      padding: 0;
    }

    p {
      max-width: 500px;
      line-height: 1.4;
    }
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .pricing-options {
    &-wrapper {
      overflow:auto;
      max-width: 100%;
      border: 1px solid #dcdcdc;
    }
    @media screen and (min-width: 1340px) {
      overflow-x: auto;
    }
  }

  .SubscriptionPlans {
    //width: 25%;
    //flex: 0 0 190px;

    .card-header {
      height: 110px;
      padding: 20px;
    }

    .plan {
      //background-color: map-get($colors, background);
      height: 100%;
      //padding: 20px;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          //margin-bottom: 5px;
          font-size: 14px;
          padding: 12px 20px;
          border-top: 1px solid #dcdcdc;
          white-space: nowrap;
        }
        .float-right {
          float: right;
          font-weight: 600;
        }
      }
    }
  }
</style>
